import React, {useState} from "react";
import { Container} from "react-bootstrap";
import {navigate} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section} from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import styled from 'styled-components';

function Startabusiness() {

  const [Q1, setQ1] = useState('')
  const [Q1BGYes, setQ1BGYes] = useState('#e7e6e6')
  const [Q1BGNo, setQ1BGNo] = useState('#e7e6e6')
  const [Q1ColorYes, setQ1ColorYes] = useState('black')
  const [Q1ColorNo, setQ1ColorNo] = useState('black')

  const [Q2, setQ2] = useState('')
  const [Q2BGYes, setQ2BGYes] = useState('#e7e6e6')
  const [Q2BGNo, setQ2BGNo] = useState('#e7e6e6')
  const [Q2ColorYes, setQ2ColorYes] = useState('black')
  const [Q2ColorNo, setQ2ColorNo] = useState('black')

  const [Q3, setQ3] = useState('')
  const [Q3BGYes, setQ3BGYes] = useState('#e7e6e6')
  const [Q3BGNo, setQ3BGNo] = useState('#e7e6e6')
  const [Q3ColorYes, setQ3ColorYes] = useState('black')
  const [Q3ColorNo, setQ3ColorNo] = useState('black')

  const [Q4, setQ4] = useState('')
  const [Q4BGYes, setQ4BGYes] = useState('#e7e6e6')
  const [Q4BGNo, setQ4BGNo] = useState('#e7e6e6')
  const [Q4ColorYes, setQ4ColorYes] = useState('black')
  const [Q4ColorNo, setQ4ColorNo] = useState('black')

  const [Q5, setQ5] = useState('')
  const [Q5BGYes, setQ5BGYes] = useState('#e7e6e6')
  const [Q5BGNo, setQ5BGNo] = useState('#e7e6e6')
  const [Q5ColorYes, setQ5ColorYes] = useState('black')
  const [Q5ColorNo, setQ5ColorNo] = useState('black')

  const [Q6, setQ6] = useState('')
  const [Q6BGYes, setQ6BGYes] = useState('#e7e6e6')
  const [Q6BGNo, setQ6BGNo] = useState('#e7e6e6')
  const [Q6ColorYes, setQ6ColorYes] = useState('black')
  const [Q6ColorNo, setQ6ColorNo] = useState('black')

  const [Q7, setQ7] = useState('')
  const [Q7BGYes, setQ7BGYes] = useState('#e7e6e6')
  const [Q7BGNo, setQ7BGNo] = useState('#e7e6e6')
  const [Q7ColorYes, setQ7ColorYes] = useState('black')
  const [Q7ColorNo, setQ7ColorNo] = useState('black')

  const [Q8, setQ8] = useState('')
  const [Q8BGYes, setQ8BGYes] = useState('#e7e6e6')
  const [Q8BGNo, setQ8BGNo] = useState('#e7e6e6')
  const [Q8ColorYes, setQ8ColorYes] = useState('black')
  const [Q8ColorNo, setQ8ColorNo] = useState('black')

  const [Q9, setQ9] = useState('')
  const [Q9BGYes, setQ9BGYes] = useState('#e7e6e6')
  const [Q9BGNo, setQ9BGNo] = useState('#e7e6e6')
  const [Q9ColorYes, setQ9ColorYes] = useState('black')
  const [Q9ColorNo, setQ9ColorNo] = useState('black')

  const YesButton = styled.button`
  max-width: 450px;
  background-color: ${props => props.backgroundColor};
  border-color: ${props => props.backgroundColor};
  height: 70px;
  margin-left: 30px;
  margin-bottom: 30px;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 25px;
  color: ${props => props.color};
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  
  :focus{
    box-shadow: ${props => props.focus};
    outline: 0px;
  }
  :hover{
    background-color: #e4234f; 
    color: white;
  }
  :visited{
    background-color: #e4234f; 
  }
  `;


  const qOne = () => {
      console.log('Q1', Q1)
      console.log('Q2', Q2)
      console.log('Q3', Q3)
      console.log('Q4', Q4)
      console.log('Q5', Q5)
      console.log('Q6', Q6)
      console.log('Q7', Q7)
      console.log('Q8', Q8)
      console.log('Q9', Q9)

      if(Q1 === ''){
        return (
          <div style={{marginTop: 150, textAlign: 'center'}}>
          <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Êtes-vous un particulier ou un entreprise ?</h3>
          <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
          <YesButton backgroundColor={Q1BGYes} color={Q1ColorYes} onClick={(c) => {setQ1('Particulier'); setQ1BGYes('rgb(228 35 79)'); setQ1BGNo('#e7e6e6'); setQ1ColorYes('white'); setQ1ColorNo('black')}}>Particulier</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('Professionnel'); setQ1BGNo('rgb(228 35 79)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>Professionnel</YesButton>
          <YesButton backgroundColor={Q1BGNo} color={Q1ColorNo} onClick={(c) => {setQ1('Entreprise'); setQ1BGNo('rgb(228 35 79)'); setQ1BGYes('#e7e6e6'); setQ1ColorNo('white'); setQ1ColorYes('black')}}>Entreprise</YesButton>
          </div>
          </div>
        )
      } else if (Q2 === ''){
        return (
          <div style={{marginTop: 150, textAlign: 'center'}}>
          <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Quel type de compte cherchez-vous ?</h3>
          <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
          <YesButton backgroundColor={Q2BGYes} color={Q2ColorYes} onClick={(c) => {setQ2('Compte individuel'); setQ2BGYes('rgb(228 35 79)'); setQ2BGNo('#e7e6e6'); setQ2ColorYes('white'); setQ2ColorNo('black')}}>Compte individuel</YesButton>
          <YesButton backgroundColor={Q2BGNo} color={Q2ColorNo} onClick={(c) => {setQ2('Compte joint'); setQ2BGNo('rgb(228 35 79)'); setQ2BGYes('#e7e6e6'); setQ2ColorNo('white'); setQ2ColorYes('black')}}>Compte joint</YesButton>
          </div>
          </div>
        )
      } else if (Q3 === ''){
        return (
          <div style={{marginTop: 150, textAlign: 'center'}}>
          <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Quels sont vos revenus net ?</h3>
          <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
          <YesButton backgroundColor={Q3BGYes} color={Q3ColorYes} onClick={(c) => {setQ3('Moins de 1000€'); setQ3BGYes('rgb(228 35 79)'); setQ3BGNo('#e7e6e6'); setQ3ColorYes('white'); setQ3ColorNo('black')}}>Moins de 1000€</YesButton>
          <YesButton backgroundColor={Q3BGNo} color={Q3ColorNo} onClick={(c) => {setQ3('Moins de 1800€'); setQ3BGNo('rgb(228 35 79)'); setQ3BGYes('#e7e6e6'); setQ3ColorNo('white'); setQ3ColorYes('black')}}>Moins de 1800€</YesButton>
          <YesButton backgroundColor={Q3BGNo} color={Q3ColorNo} onClick={(c) => {setQ3('Moins de 4000€'); setQ3BGNo('rgb(228 35 79)'); setQ3BGYes('#e7e6e6'); setQ3ColorNo('white'); setQ3ColorYes('black')}}>Moins de 4000€</YesButton>
          <YesButton backgroundColor={Q3BGNo} color={Q3ColorNo} onClick={(c) => {setQ3('Plus de 4000€'); setQ3BGNo('rgb(228 35 79)'); setQ3BGYes('#e7e6e6'); setQ3ColorNo('white'); setQ3ColorYes('black')}}>Plus de 4000€</YesButton>
          </div>
          </div>
        )
         } else if (Q4 === ''){
      return (
        <div style={{marginTop: 150, textAlign: 'center'}}>
        <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Comment faites-vous vos achats sur internet ?</h3>
        <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
        <YesButton backgroundColor={Q4BGYes} color={Q4ColorYes} onClick={(c) => {setQ4('Sur mon télephone'); setQ4BGYes('rgb(228 35 79)'); setQ4BGNo('#e7e6e6'); setQ4ColorYes('white'); setQ4ColorNo('black')}}>Sur mon télephone</YesButton>
        <YesButton backgroundColor={Q4BGNo} color={Q4ColorNo} onClick={(c) => {setQ4('Sur mon ordinateur'); setQ4BGNo('rgb(228 35 79)'); setQ4BGYes('#e7e6e6'); setQ4ColorNo('white'); setQ4ColorYes('black')}}>Sur mon ordinateur</YesButton>
        <YesButton backgroundColor={Q4BGNo} color={Q4ColorNo} onClick={(c) => {setQ4("Je n'achète pas sur internet"); setQ4BGNo('rgb(228 35 79)'); setQ4BGYes('#e7e6e6'); setQ4ColorNo('white'); setQ4ColorYes('black')}}>Je n'achète pas sur internet</YesButton>
        </div>
        </div>
      )
       } else if (Q5 === ''){
    return (
      <div style={{marginTop: 150, textAlign: 'center'}}>
      <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Avez-vous besoin d'un chéquier ?</h3>
      <div style={{marginTop: 50, textAlign: 'center'}}>
      <YesButton backgroundColor={Q5BGYes} color={Q5ColorYes} onClick={(c) => {setQ5('Oui'); setQ5BGYes('rgb(228 35 79)'); setQ5BGNo('#e7e6e6'); setQ5ColorYes('white'); setQ5ColorNo('black')}}>Oui</YesButton>
      <YesButton backgroundColor={Q5BGNo} color={Q5ColorNo} onClick={(c) => {setQ5('Non'); setQ5BGNo('rgb(228 35 79)'); setQ5BGYes('#e7e6e6'); setQ5ColorNo('white'); setQ5ColorYes('black')}}>Non</YesButton>
      <YesButton backgroundColor={Q5BGNo} color={Q5ColorNo} onClick={(c) => {setQ5('Parfois'); setQ5BGNo('rgb(228 35 79)'); setQ5BGYes('#e7e6e6'); setQ5ColorNo('white'); setQ5ColorYes('black')}}>Parfois</YesButton>
      </div>
      </div>
    )

} else if (Q6 === ''){
  return (
    <div style={{marginTop: 150, textAlign: 'center'}}>
    <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Faites-vous beaucoup de paiements en liquide ?</h3>
    <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
    <YesButton backgroundColor={Q6BGYes} color={Q6ColorYes} onClick={(c) => {setQ6('Oui'); setQ6BGYes('rgb(228 35 79)'); setQ6BGNo('#e7e6e6'); setQ6ColorYes('white'); setQ6ColorNo('black')}}>Oui</YesButton>
    <YesButton backgroundColor={Q6BGNo} color={Q6ColorNo} onClick={(c) => {setQ6('Non'); setQ6BGNo('rgb(228 35 79)'); setQ6BGYes('#e7e6e6'); setQ6ColorNo('white'); setQ6ColorYes('black')}}>Non</YesButton>
    <YesButton backgroundColor={Q6BGNo} color={Q6ColorNo} onClick={(c) => {setQ6('Parfois'); setQ6BGNo('rgb(228 35 79)'); setQ6BGYes('#e7e6e6'); setQ6ColorNo('white'); setQ6ColorYes('black')}}>Parfois</YesButton>
    </div>
    </div>
  )

} 

else if (Q7 === ''){
  return (
    <div style={{marginTop: 150, textAlign: 'center'}}>
    <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Avez-vous une montre connectée (iWatch, Samsung, Garmin, Fitbit)?</h3>
    <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
    <YesButton backgroundColor={Q7BGYes} color={Q7ColorYes} onClick={(c) => {setQ7('Oui'); setQ7BGYes('rgb(228 35 79)'); setQ7BGNo('#e7e6e6'); setQ7ColorYes('white'); setQ7ColorNo('black')}}>Oui</YesButton>
    <YesButton backgroundColor={Q7BGNo} color={Q7ColorNo} onClick={(c) => {setQ7('Non'); setQ7BGNo('rgb(228 35 79)'); setQ7BGYes('#e7e6e6'); setQ7ColorNo('white'); setQ7ColorYes('black')}}>Non</YesButton>
    <YesButton backgroundColor={Q7BGNo} color={Q7ColorNo} onClick={(c) => {setQ7("C'est quoi?"); setQ7BGNo('rgb(228 35 79)'); setQ7BGYes('#e7e6e6'); setQ7ColorNo('white'); setQ7ColorYes('black')}}>C'est quoi?</YesButton>
    </div>
    </div>
  )
}



else if (Q8 === ''){
  return (
    <div style={{marginTop: 150, textAlign: 'center'}}>
    <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Quel type de carte bancaire cherchez-vous ?</h3>
    <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
    <YesButton backgroundColor={Q8BGYes} color={Q8ColorYes} onClick={(c) => {setQ8('Carte prépayée'); setQ8BGYes('rgb(228 35 79)'); setQ8BGNo('#e7e6e6'); setQ8ColorYes('white'); setQ8ColorNo('black')}}>Carte prépayée</YesButton>
    <YesButton backgroundColor={Q8BGNo} color={Q8ColorNo} onClick={(c) => {setQ8('Visa / Mastercard'); setQ8BGNo('rgb(228 35 79)'); setQ8BGYes('#e7e6e6'); setQ8ColorNo('white'); setQ8ColorYes('black')}}>Visa / Mastercard</YesButton>
    <YesButton backgroundColor={Q8BGNo} color={Q8ColorNo} onClick={(c) => {setQ8('Premier / Gold'); setQ8BGNo('rgb(228 35 79)'); setQ8BGYes('#e7e6e6'); setQ8ColorNo('white'); setQ8ColorYes('black')}}>Premier / Gold</YesButton>
    <YesButton backgroundColor={Q8BGNo} color={Q8ColorNo} onClick={(c) => {setQ8('Infinite / World Elite'); setQ8BGNo('rgb(228 35 79)'); setQ8BGYes('#e7e6e6'); setQ8ColorNo('white'); setQ8ColorYes('black')}}>Infinite / World Elite</YesButton>
   
    </div>
    </div>
  )
} else if (Q9 === ''){
  return (
    <div style={{marginTop: 150, textAlign: 'center'}}>
    <h3 style={{fontSize: 40, color: 'rgb(28, 45, 53)'}}>Le plus important pour vous c'est ?</h3>
    <div style={{marginTop: 50, textAlign: 'center', marginLeft: -30}}>
    <YesButton backgroundColor={Q9BGYes} color={Q9ColorYes} onClick={(c) => {setQ9('Une assurance voyage'); setQ9BGYes('rgb(228 35 79)'); setQ9BGNo('#e7e6e6'); setQ9ColorYes('white'); setQ9ColorNo('black')}}>Une assurance voyage</YesButton>
    <YesButton backgroundColor={Q9BGNo} color={Q9ColorNo} onClick={(c) => {setQ9('Une conciergerie'); setQ9BGNo('rgb(228 35 79)'); setQ9BGYes('#e7e6e6'); setQ9ColorNo('white'); setQ9ColorYes('black')}}>Une conciergerie</YesButton>
    <YesButton backgroundColor={Q9BGNo} color={Q9ColorNo} onClick={(c) => {setQ9('La banque la moins chère'); setQ9BGNo('rgb(228 35 79)'); setQ9BGYes('#e7e6e6'); setQ9ColorNo('white'); setQ9ColorYes('black')}}>La banque la moins chère</YesButton>
    </div>
    </div>
  )

} 

else if (Q1 === 'Particulier' && Q8 === 'Carte prépayée' && Q9 !== ''){
  navigate('/neobanque/')
}
else if (Q1 === 'Particulier' && Q8 !== 'Carte prépayée' && Q9 !== ''){
  navigate('/banque-en-ligne/')
} 

else if (Q1 === 'Professionnel' && Q9 !== ''){
  navigate('/banque-pro/auto-entrepreneurs/')
} 
else if (Q1 === 'Entreprise' && Q9 !== ''){
  navigate('/banque-pro/sas-sasu/')
} 

// else if (Q9 !== ''){
//   navigate('/banque-pro/sas-sasu/')
// } 

      

      
 
}



    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Comparateur de banques - le classement complet avec BankBank</title>
                <meta name="description" content="Trouvez la meilleure banque selon votre profile en comparant les offres promos, cartes bancaires gratuites et les frais bancaires associés aux comptes." />
        </Helmet>
      	<Header />
      	<Section style={{minHeight: 1000, background: 'white'}}>
      	<Container style={{maxWidth: 1280}}>


{qOne()}


		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default Startabusiness
